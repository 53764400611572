/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.spacer {
  flex: 1 1 auto;
}

.warn-snackbar {
  text-align: center;
  background: #f31b24;
  color: black;
}

.allow-linebreak-snackbar {
  white-space: pre-wrap
}

mat-spinner {
  display: block;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

.go-back-btn {
  position: absolute;
  top: 0;
  left: 0;
}

.list-data-wrapper {
  display: flex;
  flex-flow: column;

  .main-text {
    mat-icon {
      display: inline;
      width: fit-content;
      height: fit-content;
      margin-left: 0 !important;
      cursor: default !important;
      font-size: 22px;
    }
  }

  .secondary-text {
    font-size: smaller;
    color: #888888;
  }
}

.body-wrapper {
  margin-left: 30%;
  margin-right: 30%;
  margin-top: 20px;

  @media only screen and (max-width: 2000px) {
    margin-left: 20%;
    margin-right: 20%;
  }

  @media only screen and (max-width: 1500px) {
    margin-left: 10%;
    margin-right: 10%;
  }

  @media only screen and (max-width: 550px) {
    margin-left: 0;
    margin-right: 0;
  }

  h1 {
    cursor: default;
  }
}

.display-none {
  display: none !important;
}

mat-icon.vegetarian-icon {
  font-size: 15pt;
  margin-left: 2px !important;
  width: fit-content;
  height: fit-content;
  color: green;
  cursor: default !important;
}

mat-icon.low-carb-icon {
  font-size: 16pt !important;
  margin-left: 2px !important;
  width: fit-content;
  height: fit-content;
  color: #eded3e;
  cursor: default !important;
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.mat-list-base .mat-list-item, .mat-list-base .mat-list-option {
  height: auto;
  padding: 3px;
}
